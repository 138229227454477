import SignatureModalRoot from 'customer/components/emoney/Wallet/Wizard/Modal/Root';
import Stepper from 'customer/components/emoney/Wallet/Wizard/Stepper/Root';
import { ReactNode, useContext } from 'react';
import { WizardContext, WizardSteps } from '../Provider';

const SignatureModal = ({
  trigger,
  children,
}: {
  trigger: ReactNode;
  children: ReactNode;
}) => {
  const { setStep, step, open, setOpen } = useContext(WizardContext);

  const getDescription = (step: WizardSteps) => {
    switch (step) {
      case 'CHAIN_SELECTION':
        return 'Select a chain';
      case 'WALLET_SELECTION':
        return 'Select a wallet';
      case 'PROVE_OWNERSHIP':
        return 'Prove ownership';
      default:
        return '';
    }
  };

  return (
    <SignatureModalRoot
      trigger={trigger}
      title={'Add a wallet'}
      description={getDescription(step)}
      open={open}
      setOpen={setOpen}
      onClose={() => setStep('CHAIN_SELECTION')}
      stepper={
        <Stepper
          currentStep={step}
          activeSteps={[
            'CHAIN_SELECTION',
            'WALLET_SELECTION',
            'PROVE_OWNERSHIP',
          ]}
        />
      }
    >
      {children}
    </SignatureModalRoot>
  );
};
export default SignatureModal;
