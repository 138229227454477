import { Chain } from '../../../types/emoney/Chain/indexV2';
import { chainSortOrder } from '../../../utils/emoney/chains';

export const sortChains = (chains: Chain[]) => {
  const orderMap = new Map<string, number>();
  chainSortOrder.forEach((chain, index) => {
    orderMap.set(chain, index);
  });

  // Sort the chains based on the order in chainSortOrder
  return chains.sort((a, b) => {
    const indexA = orderMap.get(a.chain) ?? Number.MAX_SAFE_INTEGER;
    const indexB = orderMap.get(b.chain) ?? Number.MAX_SAFE_INTEGER;
    return indexA - indexB;
  });
};
