import { ReactNode } from 'react';

import { ChainName } from 'types/emoney/Chain/indexV2';
import SignatureWizard from './SignatureWizard';

const LinkAccount = ({
  trigger,
  modal = true,
  onSuccess,
}: {
  trigger?: ReactNode;
  modal?: boolean;
  onSuccess?: (address: string, chain?: ChainName) => void;
}) => {
  return (
    <SignatureWizard
      modal={modal}
      trigger={trigger}
      onSuccess={async (signatureData, chainToLink, address) => {
        if (typeof onSuccess === 'function' && address) {
          onSuccess(address, chainToLink?.chain);
        }
      }}
    />
  );
};
export default LinkAccount;
