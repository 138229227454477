// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H7Mjk,.TNlfI{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;row-gap:16px}.OZeiL{font-size:12px}.EgQ8H{background-color:#f8f9fd;border-radius:15px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:16px;row-gap:16px}.EgQ8H .Yz4LV{font-size:18px;font-weight:600}.EgQ8H .dodxw{-webkit-column-gap:8px;-moz-column-gap:8px;column-gap:8px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:16px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signWrapper": `H7Mjk`,
	"wrapper": `TNlfI`,
	"dialog": `OZeiL`,
	"panelSummary": `EgQ8H`,
	"title": `Yz4LV`,
	"actions": `dodxw`
};
module.exports = ___CSS_LOADER_EXPORT___;
