import Stepper from 'customer/components/emoney/Wallet/Wizard/Stepper/Root';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { ReactNode, useContext } from 'react';
import { WizardContext, WizardSteps } from '../Provider';
import s from './Embedded.module.less';

const Embedded = ({ children }: { children: ReactNode }) => {
  useStyles(s);
  const { step, reconnect } = useContext(WizardContext);

  const getDescription = (step: WizardSteps) => {
    switch (step) {
      case 'CHAIN_SELECTION':
        return 'Select a chain';
      case 'WALLET_SELECTION':
        return 'Select a wallet';
      case 'PROVE_OWNERSHIP':
        return 'Prove ownership';
      default:
        return '';
    }
  };

  return (
    <div className={s.wrapper}>
      {step !== 'CHAIN_SELECTION' && (
        <button
          className={s.closeBtn}
          aria-label="close"
          type="button"
          onClick={reconnect}
        >
          <span className="material-icons">close</span>
        </button>
      )}
      <div className={s.title}>Connect wallet</div>
      <div className={s.stepWrapper}>
        <Stepper
          currentStep={step}
          activeSteps={[
            'CHAIN_SELECTION',
            'WALLET_SELECTION',
            'PROVE_OWNERSHIP',
          ]}
        />
      </div>
      <div className={s.description}>{getDescription(step)}</div>
      {children}
    </div>
  );
};
export default Embedded;
