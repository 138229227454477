import { SignatureData } from 'components/Wallet/types';
import { ReactNode } from 'react';
import { Chain } from 'types/emoney/Chain/indexV2';
import WizardContent from './Content';
import Embedded from './Embedded';
import Modal from './Modal';
import { WizardProvider } from './Provider';

const SignatureWizard = ({
  /*
   * If true, the wizard will be displayed as a modal.
   */
  modal = true,
  /**
   * The trigger element that will open the wizard.
   */
  trigger = null,
  onSuccess = async () => {},
}: {
  modal?: boolean;
  trigger?: ReactNode;
  messageToSign?: string;
  onSuccess: (
    signatureData: SignatureData,
    chain?: Chain,
    address?: string,
  ) => Promise<void>;
}) => {
  return (
    <WizardProvider isOpen={!modal} onSuccess={onSuccess}>
      {modal ? (
        <Modal trigger={trigger}>
          <WizardContent />
        </Modal>
      ) : (
        <Embedded>
          <WizardContent />
        </Embedded>
      )}
    </WizardProvider>
  );
};
export default SignatureWizard;
