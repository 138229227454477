import { useContext } from 'react';

import CosmosSelect from 'customer/components/emoney/Wallet/Wizard/Content/Wallet/Cosmos';
import EvmSelect from 'customer/components/emoney/Wallet/Wizard/Content/Wallet/Evm';
import { WizardContext } from '../../Provider';

const WalletSelection = () => {
  const { setStep, setWalletToLink, chainToLink } = useContext(WizardContext);

  const handleSelection = (wallet: string) => {
    setStep('PROVE_OWNERSHIP');
    setWalletToLink(wallet);
  };

  if (chainToLink?.kind === 'cosmos') {
    return <CosmosSelect chain={chainToLink} handleSuccess={handleSelection} />;
  }

  if (chainToLink?.kind === 'evm') {
    return <EvmSelect chain={chainToLink} handleSuccess={handleSelection} />;
  }
  return null;
};
export default WalletSelection;
