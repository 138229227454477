// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.laDNH{position:relative}.ybhpg{font-size:24px}.CsIBF,.ybhpg{display:-ms-flexbox;display:flex;-ms-flex-pack:center;font-weight:600;justify-content:center}.CsIBF{font-size:18px;margin-bottom:16px}.gdD36{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin:2rem}.guFEl{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:transparent;border:none;font-family:inherit;font-size:14px;margin:0;outline:none;padding:0;position:absolute;right:0;text-decoration:none}.guFEl span{font-size:19.6px;font-size:1.225rem;font-weight:900}.guFEl:hover{cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `laDNH`,
	"title": `ybhpg`,
	"description": `CsIBF`,
	"stepWrapper": `gdD36`,
	"closeBtn": `guFEl`
};
module.exports = ___CSS_LOADER_EXPORT___;
